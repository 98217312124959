import { Connection } from 'stardog';
//const publicDNS = 'ec2-18-118-149-1.us-east-2.compute.amazonaws.com'; //localhost
//export const endpoint = `http://${publicDNS}:5820`;
export const endpoint = 'https://pczy7ggxla.execute-api.us-east-2.amazonaws.com/prod';
export const dbname = 'dev-db';
export const systemConnection = new Connection({
  username: 'admin',
  password: 'admin',
  endpoint,
});
export const roles = [
  {
    type: 'admin',
    label: 'Administrator',
    permissions: [
      "read",
      "write",
      "grant",
      "revoke"
    ]
  },
  {
    type: 'pm',
    label: 'Project manager',
    permissions: [
      "read",
      "grant",
      "revoke"
    ]
  },
  {
    type: 'editor',
    label: 'Editor',
    permissions: [
      "read",
      "write"
    ]
  },
  {
    type: 'reader',
    label: 'Reader',
    permissions: [
      "read"
    ]
  }
];
export const getAuthHeader = ({token, username, password}) => {
  if(token)
    return `bearer ${token}`;
  const buffer = Buffer.from(`${username}:${password}`);
  const base64 = buffer.toString('base64');
  return `Basic ${base64}`;
}
export const getConnection = (getState) => {
  const username = getState().auth.user.email;
  const token = getState().auth.token;
  console.log('Credentials to use >> ', username, token);
  return new Connection({ username, token, endpoint });
}
export const generateHeaders = (headers, { getState }) => {
  const token = getState().auth.token;
  //console.log('State >> ', getState());
  //console.log('Auth headers >> ', token);
  if (token) {
    const authorization = getAuthHeader({token});
    //console.log('Authorization >> ', authorization);
    headers.set('Authorization', authorization);
  }
  //headers.set('Accept', '*/*');
  //headers.set('Access-Control-Allow-Origin', '*');
  return headers;
}

export const transformGetNamedGraphs = (response, meta, arg) => {
  //const namedGraphs = response.results.bindings.map(ng => ({uri: ng.uri.value}));
  const namedGraphs = {}
  response.results.bindings.forEach(triple => {
    //console.log('Triple >> ', triple);
    if(namedGraphs[triple.g.value] === undefined) {
      namedGraphs[triple.g.value] = {};
      namedGraphs[triple.g.value].iri = triple.g.value;
    }
    const separator = triple.p.value.includes('#') ? '#' : "/"
    const property = triple.p.value.split(separator);
    const propertyName = property[property.length - 1];
    if(namedGraphs[triple.g.value][propertyName]) {
      if(Array.isArray(namedGraphs[triple.g.value][propertyName])) {
        namedGraphs[triple.g.value][propertyName].push(triple.o.value);
      } else {
        namedGraphs[triple.g.value][propertyName] = [
          namedGraphs[triple.g.value][propertyName],
          triple.o.value
        ]
      }
    } else {
      namedGraphs[triple.g.value][propertyName] = triple.o.value;
    }
  });
  console.log('Im getting the named graphs...', response, namedGraphs)
  return Object.values(namedGraphs);
}

export const getNamedGraphs = async (baseQuery, currentDB) => {
  if(!currentDB)
    throw new Error('DB is undefined');
  console.log("NamedGraphsDB >> ", currentDB);
  const ngsInit = {
    //url: `/${dbname}/query?query=${encodeURIComponent('SELECT ?g ?p ?o WHERE { GRAPH ?g { ?g ?p ?o }}')}`,
    url: `/${currentDB}/query?query=${encodeURIComponent('SELECT ?g ?p ?o WHERE { GRAPH ?g { ?g ?p ?o }}')}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/sparql-results+json'
    }
  }
  return await baseQuery(ngsInit);
}

export const transformGetSchemasProp = (response, meta, arg) => {
  const reasoningSchemaGraphs = response.data["reasoning.schemas"];
  console.log("reasoning.schemas", reasoningSchemaGraphs);
  const schemas = reasoningSchemaGraphs.map(pair => {
    const keyValuePair = pair.split('=');
    return {
      iri: keyValuePair[1],
      name: keyValuePair[0]
    }
  });
  console.log('Schemas >> ', schemas);
  return schemas;
}

export const getSchemas = async (baseQuery, currentDB) => {
  console.log('CurrentDB >> ', currentDB);
  const schemasInit = {
    //url: `/admin/databases/${dbname}/options`,
    url: `/admin/databases/${currentDB}/options`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: {
      "reasoning.schemas": null
    }
  }
  return await baseQuery(schemasInit);
}

export const removeRoleFromUser = async (conn, username, rolename) => {
  const url = conn.request('admin', 'users', username, 'roles', rolename);
  const headers = conn.headers();
  return await fetch(url, {
    method: 'DELETE',
    headers
  })
};

export const addRoleToUser = async (conn, username, rolename) => {
  const url = conn.request('admin', 'users', username, 'roles');
  const headers = conn.headers();
  headers.set('Content-Type', 'application/json');
  return await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify({ rolename }),
  })
};