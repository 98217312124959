import React from 'react';
import { connect } from 'react-redux';
import ReportGrid from '../../components/report-grid/report-grid';
import SchemaGrid from '../../components/schema-grid/schema-grid';
import IdentifierGrid from '../../components/identifier-grid/identifier-grid';
import SchemeGrid from '../../components/scheme-grid/scheme-grid';
import NamespaceGrid from '../../components/namespace-grid/namespace-grid';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import './home.scss';

export function BaseOntology(props) {
  console.log('Base ontology props >> ', props);
  if(!props.activeBaseOntology)
    return <div>Select a base model</div>
  return (
    <TabPanel swipeEnabled={false}>
      {/* icon="textdocument" */}
      <Item title='Reports'>
        <ReportGrid title={'Reports'}/>
      </Item>
      {/* icon="detailslayout" */}
      <Item title='Models'>
        <SchemaGrid title={'Models'}/>
      </Item>
      {/* icon="detailslayout" */}
      <Item title='Namespaces'>
        <NamespaceGrid title={'Namespaces'}/>
      </Item>
    </TabPanel>
)}
const mapStateToProps = (state, ownProps) => {
  return {
    activeBaseOntology: state.active.baseOntology
  };
}
export default connect(mapStateToProps)(BaseOntology);
/*
      <Item title='Entities'>
        <TabPanel swipeEnabled={false}>
          <Item title='Identifiers'>
            <IdentifierGrid title={'Identifiers'}/>
          </Item>
          <Item title='Schemes'>
            <SchemeGrid title={'Schemes'}/>
          </Item>
        </TabPanel>
      </Item>
      <Item title='Units &amp; Measures'>
        <TabPanel swipeEnabled={false}>
          <Item title='Units'>
            <NamespaceGrid title={'Units'}/>
          </Item>
          <Item title='Measures'>
            <NamespaceGrid title={'Measures'}/>
          </Item>
        </TabPanel>
      </Item>
*/