import React, { useState, useCallback, useRef } from "react";
import { useHistory } from 'react-router';
import { useScreenSize } from '../../utils/media-query';
import { useMenuPatch } from '../../utils/patches';
import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
import { Template } from 'devextreme-react/core/template';
import {ARRANGEMENTS} from './constants';
import SideBarItem from './side-bar-item';
//import ArrangementContainer from './arrangement-container';
//import ProsePattern from './arrangements/prose-pattern';
import Arrangement from './arrangements/arrangement';
//import shortid from "shortid";
import store from '../../store/store'
import { arrangementsSlice, arrangementsSelectors } from '../../store/arrangement/arrangements'

//const shortId = shortid.generate();
//console.log('rootid', rootId);

export default function LayoutContainer(props) {
  //const [rootId, setRootId] = useState(shortId);
  const {arrangementAdded} = arrangementsSlice.actions;
  if(!(arrangementsSelectors.selectById(store.getState(), props.id)))
    store.dispatch(arrangementAdded({ id: props.id, pattern: ARRANGEMENTS[1]}))
  /*
  const [layout, setLayout] = useState({name: 'prose', label: 'Drop your arrangement here!'});
  const handleDrop = useCallback(
    (container, arrangement) => {
      console.log('Layout');
      console.log('container', container)
      console.log('arrangement', arrangement)
      setLayout({pattern: arrangement.key})
      //console.log('Layout', layout)
    }
  )
  */
  const scrollViewRef = useRef();
  const history = useHistory();
  const { isXSmall, isLarge } = useScreenSize();
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const [menuStatus, setMenuStatus] = useState(
    isLarge ? MenuStatus.Opened : MenuStatus.Closed
  );

  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    event.stopPropagation();
  }, []);

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.TemporaryOpened
        : prevMenuStatus
    );
  }, []);

  const onOutsideClick = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isLarge
        ? MenuStatus.Closed
        : prevMenuStatus
    );
  }, [isLarge]);

  const onNavigationChanged = useCallback(({ itemData: { path }, event, node }) => {
    if (menuStatus === MenuStatus.Closed || !path || node.selected) {
      event.preventDefault();
      return;
    }

    history.push(path);
    scrollViewRef.current.instance.scrollTo(0);

    if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
      setMenuStatus(MenuStatus.Closed);
      event.stopPropagation();
    }
  }, [history, menuStatus, isLarge]);
  return (
    <Drawer
        className={['drawer', patchCssClass].join(' ')}
        position={'right'}
        closeOnOutsideClick={onOutsideClick}
        openedStateMode={isLarge ? 'shrink' : 'overlap'}
        revealMode={isXSmall ? 'slide' : 'expand'}
        minSize={isXSmall ? 0 : 60}
        maxSize={150}
        shading={isLarge ? false : true}
        opened={menuStatus === MenuStatus.Closed ? false : true}
        template={'tools'}
      >
        <div className={'container layout-container'}>
          <ScrollView className={'layout-body with-footer'}>
            <Arrangement id={props.id} />
              {/*
                <ProsePattern key={rootId} id={rootId} />
                <ArrangementContainer key="" model={root} onDrop={handleDrop} />
              */}
          </ScrollView>
        </div>
        <Template name={'tools'}>
          <div className="body">
            <div className="sideBar">
              {Object.values(ARRANGEMENTS).map(item => (
                <SideBarItem key={item.id} data={item} />
              ))}
            </div>
          </div>
        </Template>
      </Drawer>
  );
}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3
};