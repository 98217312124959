import {
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
    
export const participantsAdapter = createEntityAdapter({
  selectId: participant => participant.name,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const participantsSlice = createSlice({
  name: 'participants',
  initialState: participantsAdapter.getInitialState(),
  reducers: {
    participantsReceived(state, action) {
      console.log('Inside participants reveiced >> ', state, action);
      participantsAdapter.setAll(state, action.payload);
    },
    participantUpdated: participantsAdapter.updateOne,
    participantRemoved: participantsAdapter.removeOne
  },
});

export const participantsSelectors = participantsAdapter.getSelectors(state => state.participants);

export const { 
  participantsReceived
  , participantUpdated
  , participantRemoved
} = participantsSlice.actions;