import React, {useState, useCallback} from 'react';
import ArrangementContainer from './arrangement-container';
import store from '../../../store/store';
import { arrangementsSlice } from '../../../store/arrangement/arrangements';
import { arrangementLinksSlice } from '../../../store/arrangement/arrangement-links';
import { deleteDependencies } from './utils';

export default function WholePartsPattern(props) {

  const {arrangementAdded, arrangementUpdated, arrangementRemoved} = arrangementsSlice.actions;
  const {arrangementLinkAdded, arrangementLinkUpdated, arrangementLinkRemoved} = arrangementLinksSlice.actions;
  
  const [whole, setWhole] = useState({name: 'whole', label: 'Whole'});
  const [parts, setParts] = useState([]);
  const [newPart, setNewPart] = useState({name: 'parts', label: 'Insert a new part'});

  let currentNestedPattern;

  const setNestedPattern = (state, props) => ({
    ...state,
    pattern: currentNestedPattern
  });

  const createPart = (state, props) => {
    console.log(state);
    return [
    ...state,
    {
      id: state.length + 1,
      name: 'part',
      label: 'Part',
      pattern: currentNestedPattern
    }
  ]}

  const insertWhole = useCallback(
    (container, arrangement) => {
      //console.log('container', container)
      //console.log('arrangement', arrangement)
      currentNestedPattern = arrangement.key;
      if(container.name === 'whole') {
        setWhole(setNestedPattern);
      }
    }
  )

  const insertPart = useCallback(
    (container, arrangement) => {
      //console.log('container', container)
      //console.log('arrangement', arrangement)
      currentNestedPattern = arrangement.key;
      if(container.name === 'parts') {
        setParts(createPart);
      }
    }
  )

  const updateWhole = () => {
    
  }

  const updatePart = () => {
    
  }

  const removeWhole = () => {
    
  }

  const removePart = () => {
    
  }

  return (
    <React.Fragment>
    <ArrangementContainer
      model={whole}
      onDrop={insertWhole}
      onUpdate={updateWhole}
      onDestroy={removeWhole} />
    {
      parts.map(part => (
        <ArrangementContainer
          key={part.to}
          id={part.to}
          model={part}
          onUpdate={updatePart}
          onDestroy={removePart} />)
      )
    }
    <ArrangementContainer 
      model={newPart}
      onDrop={insertPart} />
    </React.Fragment>
  )
}