import { Connection, query, user } from 'stardog';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { endpoint, systemConnection } from './common';
/*
import { gql } from 'graphql-request';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import config from '../aws-exports';
const { endpoint } = config.aws_cloud_logic_custom[0];
*/

export async function addUser (name, password) {
  const userConfig = {
    name,
    password
  }
  console.log('userConfig ', userConfig);
  try {
    const result = await user.create(systemConnection, userConfig);
    if(result) {
      const stdRoleResult = await user.setRoles(systemConnection, name, ['std-user']);
      console.log('Role result >> ', stdRoleResult);
      return result;
    }
  } catch {
    return {
      isOk: false,
      message: "Failed to add user"
    }
  }
}

export async function changeUserPassword(username, password) {
  try {
    return await user.changePassword(systemConnection, username, password)
    //console.log('Change password result', result);
  } catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function getToken(username, password) {
  try {
    const connection = new Connection({
      username,
      password,
      endpoint,
    });
    const result = await user.token(connection);
    const token = result.body.token;
    console.log('Token from db >> ', token);
    return {
      isOk: true,
      token
    }
  } catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

//export const { useGetTokenQuery } = tokenAPI;

//export const { useGetHelloQuery } = sbrmAPI;