import {
  CLOSE_EDITING,
  SET_CHANGES,
  SET_EDIT_ROW_KEY,
} from './actions.js';

export default function reducer(state, { type, payload }) {
  console.log('From reducer state and type >> ', state, type);
  switch (type) {
    case CLOSE_EDITING:
      return {
        ...state,
        changes: [],
        editRowKey: null,
      };
    case SET_CHANGES:
      return {
        ...state,
        changes: payload,
      };
    case SET_EDIT_ROW_KEY:
      return {
        ...state,
        editRowKey: payload,
      };
    default:
      return state;
  }
}