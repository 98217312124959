//import defaultUser from '../utils/default-user';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
//import { Connection, query, user as dbuser } from 'stardog';
import { addUser, changeUserPassword, getToken } from './unauth';

/*
import { useDispatch } from 'react-redux';
import { setCredentials } from '../store/slicers/user';
import { useGetTokenQuery } from '../api/user';
*/

Amplify.configure(awsconfig);

export async function signIn(email, password) {
  const username = email;
  try {
      console.log(email, password);
      const user = await Auth.signIn(username, password);
      console.log('user', user);
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken();
      const jwt = accessToken.getJwtToken();
      console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
      console.log(`myJwt: ${jwt}`);
      //const answer = await getToken(username, password);
      //console.log('DB token >> ', answer);
      //const token = answer.isOk ? answer.token : '';
    return {
      isOk: true,
      data: user
      //token
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function signOut() {
  try {
    await Auth.signOut();
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Sign out failed"
    };
  }
}

export async function getUser() {
  try {
    // Send request
    const user = await Auth.currentUserInfo();
    return {
      isOk: true,
      data: user
    };
    //defaultUser
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email, password) {
  const username = email;
  try {
    const dbUser = await addUser(username, password);  
    console.log('DB User created >> ', dbUser);
    const request = {
      username,
      password,
      attributes: {
        email
      }
    }
    const { user } = await Auth.signUp(request);
    console.log(user);
    return {
      isOk: true
    };
  }
  catch(e) {
    return {
      isOk: false,
      message: e.message
    };
  }
}

export async function changePassword(email, code, password) {
  try {
    // Send request
    const username = email;
    const response = await changeUserPassword(username, password);
    if(response.ok) {
      console.log('DB User created >> ', response);
      const data = await Auth.forgotPasswordSubmit(username, code, password)
      console.log('AWS Data ', data);
      return {
        isOk: true
      };
    }
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    const username = email;
    const data = await Auth.forgotPassword(username);
    console.log(data);
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}

/*
const easyQuery = async (username, password) => {
  const localDNS = 'localhost';
  const publicDNS = 'ec2-3-141-38-104.us-east-2.compute.amazonaws.com';
  const endpoint = `http://${publicDNS}:5820`;
  const conn = new Connection({
    username,
    password,
    //token: jwt,
    endpoint,
  });

  const tokenResult = await dbuser.token(conn);
  const token = tokenResult.body.token;
  console.log('Token from db >> ', token);

  const tokenConn = new Connection({
    token,
    endpoint,
  });
  
  const result = await query.execute(
    tokenConn
    , 'constraint-examples'
    , 'select ?s ?p ?o from <https://www.omg.org/spec/SBRM/> where { ?s ?p ?o }'
    , 'application/sparql-results+json'
    , {
        limit: 10,
        reasoning: false,
        offset: 0,
      })
  console.log('query result >> ', result.body.results.bindings);
  
}
*/