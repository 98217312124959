import React, { useState, useReducer, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAddNamespacesMutation } from '../../api/namespace';
import { useGetNamespacesQuery } from '../../api/namespace';
import { namespacesReceived } from '../../store/namespaces';
import { namespacesSelectors } from '../../store/namespaces';
import store from '../../store/store';

import reducer from './reducer.js';
import { closeEditing, setChanges, setEditRowKey } from './actions.js';

import DataGrid, {
  Column,
  Editing,
  Paging,
  Selection,
  Toolbar,
  Item,
} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';

import applyChanges from 'devextreme/data/apply_changes';

const loadPanelPosition = { of: '#namespace-grid' };

const nsJsonToTurtle = (namespaces) => {
  const initialValue = '';
  const nsTurtle = namespaces.reduce((previousValue, currentValue) => {
    return `${previousValue}@prefix ${currentValue.prefix}: <${currentValue.name}> .\r\n`
  },
  initialValue);
  console.log('Turtle >> ', nsTurtle);
  return nsTurtle;
}

const initialState = {
  changes: [],
  editRowKey: null,
};

export default function ReportGrid() {
  const db = store.getState().active.baseOntology.name;
  const dispatcher = useDispatch();
  const { data = [], isLoading, isFetching, isError, isSuccess } = useGetNamespacesQuery(db);
  const [ addNamespaces, {
    data: addedData, 
    isLoading: isAdding,
    isError: isAddError,
    isSuccess: isAddSuccess
  }] = useAddNamespacesMutation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);

  //console.log('Data >> ', data);
  //console.log('State before >>', store.getState().namespaces);
  dispatcher(namespacesReceived(data));
  //console.log('State after >> ', store.getState().namespaces);
  const namespaces = namespacesSelectors.selectAll(store.getState());
  //console.log("Selected ns >> ", namespaces);

  const handleAddMany = async (newData) => {
    const request = nsJsonToTurtle(newData);
    const newNamespaces = await addNamespaces({db: db, body: request}).unwrap();
    console.log('closeEditing ', closeEditing());
    dispatch(closeEditing());
    return newNamespaces ? newNamespaces : null;
  }

  const selectionChanged = (data) => {
    setSelectedItemKeys(data.selectedRowKeys);
  }

  const onSaving = useCallback((e) => {
    e.cancel = true;
    console.log('Event >> ', e);
    const change = e.changes[0];
    if (change && change.type) {
      console.log('namespaces >> ', namespaces);
      console.log('change >> ', change);
      const newData = applyChanges(namespaces, [change], { keyExpr: 'name' });
      console.log('newData >> ', newData);
      e.promise = handleAddMany(newData);
    } else {
      console.log('closeEditing ', closeEditing());
      dispatch(closeEditing());
    }
  }, []);

  const onChangesChange = useCallback((changes) => {
    console.log('changes', changes);
    //setChanges(dispatch, changes);
    dispatch(setChanges(changes));
  }, []);

  const onEditRowKeyChange = useCallback((editRowKey) => {
    console.log('editRowKey', editRowKey);
    //setEditRowKey(dispatch, editRowKey);
    dispatch(setEditRowKey(editRowKey));
  }, []);
  
  return (
    <div id="namespace-grid-wrapper">
      <LoadPanel
        position={loadPanelPosition}
        visible={isLoading || isAdding}
      />
      {isFetching && <div>Fetching...</div>}
      {isError && <div>Something went wrong</div>}
      {isSuccess && (
        <DataGrid id="namespace-grid"
          keyExpr="name"
          dataSource={namespaces}
          showBorders
          repaintChangesOnly
          selectedRowKeys={selectedItemKeys}
          onSelectionChanged={selectionChanged}
          onSaving={onSaving}
          >
          <Selection mode="multiple" />
          <Paging enabled={false} />
          <Editing
            mode="row"
            allowAdding
            changes={state.changes}
            onChangesChange={onChangesChange}
            editRowKey={state.editRowKey}
            onEditRowKeyChange={onEditRowKeyChange}
          />
          <Column dataField="prefix" caption="Prefix" width={100} />
          <Column dataField="name" caption="URI" />
        </DataGrid>
      )}
      {!data && <div>No namespaces</div>}
    </div>
  );
}