import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  endpoint
  , generateHeaders
} from './common';

export const baseOntologyAPI = createApi({
  reducerPath: 'baseOntologyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: endpoint,
    prepareHeaders: generateHeaders
  }),
  tagTypes: ["BaseOntology"],
  endpoints: (build) => ({
    getBaseOntologies: build.query({
      query: () => ({
        url: `/admin/databases`,
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      }),
      transformResponse: (response, meta, arg) => {
        console.log('Response', response);
        if(response.databases)
          return response.databases.map(db => ({name: db}));
        return [];
      },
      providesTags: ["BaseOntology"]
    }),
  })
});

export const {
  useGetBaseOntologiesQuery,
} = baseOntologyAPI;
