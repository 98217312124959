import React from 'react';
//import { useState, useEffect } from 'react'
import { useDrop } from 'react-dnd';
import {ARRANGEMENT} from '../constants';
import Arrangement from './arrangement';
import '../common.scss';

export default function ArrangementContainer(props) {
  
  const [{ canDrop, isOver, isOverCurrent }, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: ARRANGEMENT,
    drop: (item, monitor) => {
      //console.log('props', props);
      const didDrop = monitor.didDrop();
      if(didDrop)
        return;
      props.onDrop(props.model, item);
    },
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({shallow: true}),
      canDrop: monitor.canDrop()
    })
  }))
  //console.log('Initial', props);
  let arrangement;
  if(props.id)
    arrangement = <Arrangement id={props.id} onDestroy={props.onDestroy} onUpdate={props.onUpdate}/>

  return (
    <div
      ref={drop}
      style={{ backgroundColor: isOverCurrent && isOver ? '#262630' : '#363640' }}
      className="arrangement-container"
    >
      <div>{props.model.label}</div>
      {arrangement}
    </div>
  )
}