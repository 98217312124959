import React, {useState, useCallback} from 'react';
import { useDrag } from 'react-dnd';
import store from '../../../store/store';
import { arrangementsSlice, arrangementsSelectors } from '../../../store/arrangement/arrangements';
import shortid from "shortid";
import {ARRANGEMENT
        , SINGLE_PATTERN
        , PROSE_PATTERN
        , WHOLE_PARTS_PATTERN
        , AGGREGATION_PATTERN
        , CHANGE_PATTERN
        , IDENTITY_PATTERN
        , FUNCTION_PATTERN} from '../constants';
import SinglePattern from './single-pattern';
import ProsePattern from './prose-pattern';
import WholePartsPattern from './whole-parts-pattern';
import AggregationPattern from './aggregation-pattern';
import ChangePattern from './change-pattern';
import IdentityPattern from './identity-pattern';
import FunctionPattern from './function-pattern';
import ArrangementHeader from "./arrangement-header";
import '../common.scss';

export default function Arrangement(props) {
  
  //console.log('Arrangement', props.id);
  const {arrangementAdded, arrangementUpdated} = arrangementsSlice.actions;
  /*
  if(!(arrangementsSelectors.selectById(store.getState(), props.id)))
    store.dispatch(arrangementAdded({ id: props.id, pattern: ARRANGEMENTS[1], children: []}));
  */
 /*
  useEffect(() => {
    console.log('Mount', store.getState());
    return () => {
      console.log('Unmount', store.getState());
    };
  });
  */
  
  const arrangement = arrangementsSelectors.selectById(store.getState(), props.id);
  //console.log(arrangement);
  //console.log(store.getState());
  //{name: 'children', label: 'Children'}
  

  const [children, setChildren] = useState([]);
  const [newChild, setNewPart] = useState({name: 'children', label: 'Insert a new child'});

  let currentNestedPattern;
  let currentChildId;

  const createChild = (state, props) => {
    const child = {
      id: shortid.generate(),
      role: 'child',
      label: 'Child',
      from: arrangement.id,
      to: currentChildId
    }
    //console.log(state);
    return [
    ...state,
    child
  ]}

  const insertRelationship = useCallback(
    (container, pattern) => {
      //console.log('Prose')
      //console.log('container', container)
      //console.log('arrangement', arrangement)
      //currentNestedPattern = arrangement.key;
      currentChildId = shortid.generate();
      store.dispatch(arrangementAdded({ id: currentChildId, pattern, children: []}))
      
      if(container.name === 'children') {
        setChildren(createChild);
        //const children = [...arrangement.children, child];
        //store.dispatch(arrangementUpdated({ id: props.id, changes: {children}}));
      }
    }
  )

  const update = () => {
    props.onUpdate(arrangement);
  }

  const destroy = () => {
    props.onDestroy(arrangement);
  }

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: ARRANGEMENT,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }))

  let components;
  switch (arrangement.pattern.kind) {
    case SINGLE_PATTERN:
      components = <SinglePattern id={props.id} />
      break;
    case PROSE_PATTERN:
      components = <ProsePattern id={props.id} />
      break;
    case WHOLE_PARTS_PATTERN:
      components = <WholePartsPattern id={props.id} />
      break;
    case AGGREGATION_PATTERN:
      components = <AggregationPattern id={props.id} />
      break;
    case CHANGE_PATTERN:
      components = <ChangePattern id={props.id} />
      break;
    case IDENTITY_PATTERN:
      components = <IdentityPattern id={props.id} />
      break;
    case FUNCTION_PATTERN:
      components = <FunctionPattern id={props.id} />
      break;
    default:
      components = 'Drop your arrangement here!'
      break;
  }

  return (
    <div ref={dragPreview}
      style={{ opacity: isDragging ? 0.5 : 1}}>
      <div ref={drag} className='arrangement'>
        <ArrangementHeader
          parent={arrangement}
          onUpdate={update}
          onDestroy={destroy}/>
        {components}
      </div>
    </div>
  )
}