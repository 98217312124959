//https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
import React, { useState, useReducer, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useGetUsersQuery } from '../../api/user';
import { 
  useGetParticipantsQuery
  , useAddParticipantMutation
  , useRemoveParticipantMutation
} from '../../api/participant';
import { usersReceived } from '../../store/users';
import { usersSelectors } from '../../store/users';
import { participantsReceived } from '../../store/participants';
import { participantsSelectors } from '../../store/participants';
import { selectActiveReport } from '../../store/slicers/active';
import { roles } from '../../api/common';
import store from '../../store/store';

import reducer from './reducer.js';
import { closeEditing, setChanges, setEditRowKey } from './actions.js';

import DataGrid, {
  Column,
  Lookup,
  Editing,
  Paging,
  Selection,
  Button
} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import SchemaMultiselector from './schema-multiselector';

const loadPanelPosition = { of: '#participant-grid' };

const initialState = {
  changes: [],
  editRowKey: null,
};

export default function ParticipantGrid() {
  
  const report = selectActiveReport(store.getState());
  console.log("Active report >> ", report);

  const dispatcher = useDispatch();

  const { data: uData = []
    , isLoading: uIsLoading
    , isFetching: uIsFetching
    , isError: uIsError
    , isSuccess: uIsSuccess
    , error: uError } = useGetUsersQuery();

  const { data = []
    , isLoading
    , isFetching
    , isError
    , isSuccess
    , error } = useGetParticipantsQuery({ iri: report.iri });
  
  const [ addParticipant, {
    data: addedData, 
    isLoading: isAdding,
    isError: isAddError,
    isSuccess: isAddSuccess
  }] = useAddParticipantMutation();
  
  const [ removeParticipant, {
    data: deletedData, 
    isLoading: isDeleting,
    isError: isDeleteError,
    isSuccess: isDeleteSuccess
  }] = useRemoveParticipantMutation();

  dispatcher(usersReceived(uData));
  dispatcher(participantsReceived(data));
  
  const users = usersSelectors.selectAll(store.getState());
  const participants = participantsSelectors.selectAll(store.getState());

  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);

  const selectionChanged = (data) => {
    setSelectedItemKeys(data.selectedRowKeys);
  }

  const handlePut = async (da) => {
    const options = { da };
    console.log('Options', options)
    const newNamedGraph = await addParticipant(options).unwrap();
    console.log('closeEditing ', closeEditing());
    dispatch(closeEditing());
    return newNamedGraph ? newNamedGraph : null;
  }

  const handleDelete = async (iri) => {
    const deletedNamedGraph = await removeParticipant(iri).unwrap();
    console.log('deletedNamedGraph >> ', deletedNamedGraph);
    console.log('closeEditing ', closeEditing());
    dispatch(closeEditing());
    return deletedNamedGraph ? deletedNamedGraph : null;
  }

  const onSaving = useCallback((e) => {
    e.cancel = true;
    //console.log('Event >> ', e);
    //console.log('changes >> ', e.changes);
    const change = e.changes[0];
    if (change && change.type) {
      if(change.type === 'insert') {
        //console.log('namedGraphs >> ', namedGraphs);
        //console.log('change >> ', change);
        //const newData = applyChanges(namedGraphs, [change], { keyExpr: 'iri' });
        //console.log('newData >> ', newData);
        console.log('Insert change.data >> ', change.data);
        e.promise = handlePut(change.data);
      } else if (change.type === 'remove') {
        console.log('Delete change.data >> ', change.key);
        e.promise = handleDelete(change.key);
      } //else if (change.type === 'update') {}
    } else {
      console.log('closeEditing ', closeEditing());
      dispatch(closeEditing());
    }
  }, []);

  const onChangesChange = useCallback((changes) => {
    console.log('changes', changes);
    //setChanges(dispatch, changes);
    dispatch(setChanges(changes));
  }, []);

  const onEditRowKeyChange = useCallback((editRowKey) => {
    console.log('editRowKey', editRowKey);
    //setEditRowKey(dispatch, editRowKey);
    dispatch(setEditRowKey(editRowKey));
  }, []);

  const userItemRender = (data) => {
    if (data != null) {
      return <div>
        <div><b>{data.email}</b></div>
      </div>;
    }
    return <span>(All)</span>;
  }

  const roleItemRender = (data) => {
    if (data != null) {
      return <div>
        <div><b>{data.label}</b></div>
      </div>;
    }
    return <span>(All)</span>;
  }

  function userEditorRender(cell) {
    const onValueChanged = (e) => {
      //console.log('onValueChanged >> ', cell, e);
      cell.setValue(e.value);
    }
    return <SelectBox
      searchEnabled
      defaultValue={cell.value}
      {...cell.column.lookup}
      onValueChanged={onValueChanged}
      itemRender={userItemRender}
    />;
  }

  function roleEditorRender(cell) {
    const onValueChanged = (e) => {
      //console.log('onValueChanged >> ', cell, e);
      cell.setValue(e.value);
    }
    return <SelectBox
      searchEnabled
      defaultValue={cell.value}
      {...cell.column.lookup}
      onValueChanged={onValueChanged}
      itemRender={roleItemRender}
    />;
  }

  const isOpenIconVisible = (e) => {
    return !e.row.isEditing;
  }

  const cellTemplate = (container, options) => {
    const noBreakSpace = '\u00A0';
    const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
    container.textContent = text || noBreakSpace;
    container.title = text;
  }

  const calculateFilterExpression = (filterValue, selectedFilterOperation, target) => {
    if (target === 'search' && typeof (filterValue) === 'string') {
      return [this.dataField, 'contains', filterValue];
    }
    return function(data) {
      return (data["imports"] || []).indexOf(filterValue) !== -1;
    };
  }

  //render() {
  return (
    <div id="participant-grid-wrapper">
      <LoadPanel
        position={loadPanelPosition}
        visible={(isLoading || uIsLoading ) || (isAdding || isDeleting )}
      />
      {(isFetching || uIsFetching ) && <div>Fetching...</div>}
      {(isError || uIsError ) && <div>Something went wrong</div>}
      {(isSuccess && uIsSuccess ) && (
        <DataGrid id="participant-grid"
          keyExpr="name"
          dataSource={participants}
          showBorders
          repaintChangesOnly
          selectedRowKeys={selectedItemKeys}
          onSelectionChanged={selectionChanged}
          onSaving={onSaving}
        >
          <Selection mode="multiple" />
          <Paging enabled={false} />
          <Editing
            mode="row"
            useIcons
            allowAdding
            allowDeleting
            changes={state.changes}
            onChangesChange={onChangesChange}
            editRowKey={state.editRowKey}
            onEditRowKeyChange={onEditRowKeyChange}
            />
          <Column
            dataField="name"
            caption="User"
            width={200}
            editCellRender={userEditorRender}
          >
            <Lookup
              dataSource={users}
              valueExpr="email"
              displayExpr="email"
            />  
          </Column>
          <Column
            dataField="roleType"
            caption="Permissions"
            width={250}
            allowSorting={false}
            editCellRender={roleEditorRender}>
            <Lookup
              dataSource={roles}
              valueExpr="type"
              displayExpr="label"
            />
          </Column>
        </DataGrid>
        )}
    </div>
  );
  //}
  /*
  deleteRecords() {
    this.state.selectedItemKeys.forEach((key) => {
      dataSource.store().remove(key);
    });
    this.setState({
      selectedItemKeys: [],
    });
    dataSource.reload();
  }

  selectionChanged(data) {
    this.setState({
      selectedItemKeys: data.selectedRowKeys,
    });
  }
  */
}

//export default ReportGrid;
//allowSorting="false"
//editCellComponent={NamespaceSelector}
//cellTemplate={namespacePrefixTemplate}
//calculateFilterExpression={calculateFilterExpression}

/*
"http://purl.org/vocab/vann/preferredNamespaceUri"
"http://purl.org/dc/terms/title"
"http://purl.org/dc/terms/date"
"http://purl.org/dc/terms/creator"
*/

/*
<Toolbar>
  <Item name="addRowButton" showText="always" />
  <Item location="after">
    <Button
      onClick={() => ({})}
      icon="trash"
      disabled={!selectedItemKeys.length}
      text="Delete Selected Records" />
  </Item>
</Toolbar>
*/

/*
          <Column
            dataField="imports"
            caption="Use"
            width={200}
            allowSorting={false}
            editCellComponent={NamespaceSelector}
            cellTemplate={cellTemplate}
            calculateFilterExpression={calculateFilterExpression}>
            <Lookup
              dataSource={namespaces}
              valueExpr="name"
              displayExpr="prefix"
            />
          </Column>
*/
/*
          <Column type="buttons" width={200}>
            <Button name="delete" />
            <Button
              icon="folder"
              hint="Open"
              visible={isOpenIconVisible}
              onClick={activateNamedGraph}
            />
          </Column>
*/
/*
{!data && <div>There is no reports yet</div>}
*/
