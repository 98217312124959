import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { endpoint, generateHeaders } from './common';

export const permissionAPI = createApi({
  reducerPath: 'permissionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: endpoint,
    prepareHeaders: generateHeaders
  }),
  tagTypes: ["Permission"],
  endpoints: (build) => ({
    getEffectiveUserPermissions: build.query({
      query: (username) => ({
        url: `/admin/permissions/effective/user/${username}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      providesTags: ["Permission"],
      transformResponse: (response, meta, arg) => {
        console.log('Im getting the permissions...', response);
        return response.permissions;
      },
    }),
    getUserPermissions: build.query({
      query: (username) => ({
        url: `/admin/permissions/user/${username}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      providesTags: ["Permission"],
      transformResponse: (response, meta, arg) => {
        console.log('Im getting the permissions...', response);
        return response.permissions;
      },
    }),
    addUserPermission: build.mutation({
      query: ({username, body}) => ({
        url: `/admin/permissions/user/${username}`,
        method: 'PUT',
        body: body,
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ["Permission"],
      transformResponse: (response, meta, arg) => {
        console.log('Im adding permission...', response);
        return response;
      }
    }),
    deleteUserPermission: build.mutation({
      query: ({username, body}) => ({
        url: `/admin/permissions/user/${username}/delete`,
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ["Permission"],
      transformResponse: (response, meta, arg) => {
        console.log('Im deleting permission...', response);
        return response;
      }
    }),
  })
});

export const {
  useGetEffectiveUserPermissionsQuery
  , useGetUserPermissionsQuery
  , useAddUserPermissionMutation
  , useDeleteUserPermissionMutation
} = permissionAPI;