import {
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
  
export const arrangementsAdapter = createEntityAdapter({
  // Assume IDs are stored in a field other than `book.id`
  selectId: arrangement => arrangement.id,
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => a.id > b.id,
});

export const arrangementsSlice = createSlice({
  name: 'arrangement',
  initialState: arrangementsAdapter.getInitialState(),
  reducers: {
    // Can pass adapter functions directly as case reducers.  Because we're passing this
    // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
    arrangementAdded: arrangementsAdapter.addOne,
    arrangementsReceived(state, action) {
      // Or, call them as "mutating" helpers in a case reducer
      arrangementsAdapter.setAll(state, action.payload.arrangements)
    },
    arrangementUpdated: arrangementsAdapter.updateOne,
    arrangementRemoved: arrangementsAdapter.removeOne
  },
});

// Can create a set of memoized selectors based on the location of this entity state
export const arrangementsSelectors = arrangementsAdapter.getSelectors(state => state.arrangements);