import { user, Connection } from 'stardog';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  endpoint
  , systemConnection
  , roles
  , getConnection
  , generateHeaders
  , addRoleToUser
  , removeRoleFromUser
} from './common';

import murmurHash3 from 'murmurhash3js';

export const participantAPI = createApi({
  reducerPath: 'participantApi',
  baseQuery: fetchBaseQuery({
    baseUrl: endpoint,
    prepareHeaders: generateHeaders
  }),
  tagTypes: ["Participant"],
  endpoints: (build) => ({
    getParticipants: build.query({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        console.log('Arg passed to GetParticipants >> ', arg)
        try {
          //const connection = getConnection(getState);
          const hashcode = murmurHash3.x86.hash32(arg.iri);
          const participants = []
          for (let i = 0; i < roles.length; i++) {
            const role = roles[i];
            const rolename = `r-${role.type}-${hashcode}`;
            console.log('Role name >> ', rolename);
            const response = await user.role.usersWithRole(systemConnection, rolename );
            console.log('Response >> ', response);
            const users = response.body.users;
            console.log('Users >> ', users);
            for ( let j = 0; j < users.length; j++) {
              const participant = users[j];
              console.log("username ", participant);
              participants.push( { name: participant, roleType: role.type, roleLabel: role.label, rolename } );
            }
          }
          return { data: participants }
        } catch (e) {
          return { error: e };
        }
      },
      providesTags: ["Participant"]
    }),
    addParticipant: build.mutation({
      queryFn: async (arg, { signal, dispatch, getState }, extraOptions, baseQuery)  => {
        const connection = getConnection(getState);
        const result = await addRoleToUser(connection, arg.username, arg.rolename);
        console.log('Result from addParticipant >> ', result);
        return result;
      },
      invalidatesTags: ["Participant"]
    }),
    removeParticipant: build.mutation({
      queryFn: async (arg, { signal, dispatch, getState }, extraOptions, baseQuery)  => {
        const connection = getConnection(getState);
        const result = await removeRoleFromUser(connection, arg.username, arg.rolename);
        console.log('Result from removeParticipant >> ', result);
        return result;
      },
      invalidatesTags: ["Participant"],
    }),
  })
});

export const {
  useGetParticipantsQuery,
  useAddParticipantMutation,
  useRemoveParticipantMutation,
} = participantAPI;