import {
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
    
export const schemasAdapter = createEntityAdapter({
  selectId: schema => schema.iri,
  sortComparer: (a, b) => a.iri.localeCompare(b.iri),
});

export const schemasSlice = createSlice({
  name: 'schemas',
  initialState: schemasAdapter.getInitialState(),
  reducers: {
    schemasReceived(state, action) {
      console.log('Inside schemas reveiced >> ', state, action);
      schemasAdapter.setAll(state, action.payload);
    },
    schemaUpdated: schemasAdapter.updateOne,
    schemaRemoved: schemasAdapter.removeOne
  },
});

export const schemasSelectors = schemasAdapter.getSelectors(state => state.schemas);

export const { 
  schemasReceived
  , schemaUpdated
  , schemaRemoved
} = schemasSlice.actions;