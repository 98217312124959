import React from 'react';
import { useDispatch } from 'react-redux';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { 
  useGetBaseOntologiesQuery
} from '../../api/base-ontology';
import { baseOntologiesReceived } from '../../store/baseOntologies';
import { baseOntologiesSelectors } from '../../store/baseOntologies';
import { activateBaseOntology } from '../../store/slicers/active';
import store from '../../store/store';
import './base-ontology-selector.scss';

const loadPanelPosition = { of: '#base-onto-selector-container' };

export default function BaseOntologySelector() {
  
  const dispatcher = useDispatch();

  const {
    data = []
    , isLoading
    , isFetching
    , isError
    , isSuccess } = useGetBaseOntologiesQuery();

  dispatcher(baseOntologiesReceived(data));
  
  const baseOntologies = baseOntologiesSelectors.selectAll(store.getState());

  const handleChange = (e) => {
    console.log('Event change >> ', e);
    dispatcher(activateBaseOntology(e.value));
  };
  
  return (
    <div className="widget-container">
      <div className="dx-fieldset">
        <div id="base-onto-selector-container" className="dx-field">
          <div id="base-onto-selector-label" className="dx-field-label">Base model</div>
          <LoadPanel
            position={loadPanelPosition}
            visible={isLoading}/>
          {isFetching && <div>Fetching...</div>}
          {isError && <div>Something went wrong</div>}
          {(isSuccess) && (
            <div className="dx-field-value">
              <SelectBox dataSource={baseOntologies}
                displayExpr="name"
                searchEnabled={true}
                searchMode="contains"
                onValueChanged={handleChange}/>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
