import React, {useState} from 'react';

import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

export default function ConfirmDialog(props) {

  //console.log("ConfirmDialog", props.visible);

  const [confirmButtonOptions, setConfirmButtonOptions] = useState({
    text: 'Ok',
    onClick: props.onConfirm
  });

  const [cancelButtonOptions, setCancelButtonOptions] = useState({
    text: 'Cancel',
    onClick: props.onCancel
  });

	return (
		<Popup
		  visible={props.visible}
		  onHiding={props.onCancel}
		  dragEnabled={false}
		  closeOnOutsideClick={false}
		  showCloseButton={false}
		  showTitle={true}
		  title={props.title}
		  container=".dx-viewport"
		  width={300}
		  height={150}
		>
			<ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="before"
        options={confirmButtonOptions}
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={cancelButtonOptions}
      />
		</Popup>
	)
}