import React, { useRef, useCallback } from 'react';
import List from "devextreme-react/list";
import { useScreenSize } from '../../utils/media-query';
import './data-source-navigator.scss';

import * as events from 'devextreme/events';

const navigation = [
  { id: 1, text: "Seattle Method", icon: "exportxlsx" },
  { id: 2, text: "XBRL Files", icon: "file" }
];

export default function DataSourceNavigator(props) {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props;

  const { isLarge } = useScreenSize();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement)
      events.off(prevElement, 'dxclick');
    wrapperRef.current = element;
    events.on(element, 'dxclick', e => {
      openMenu(e);
    });
  }, [openMenu]);
  
  const closeDrawer = () => {
    this.props.stateHandler({ isDrawerOpen: false });
  }

  const renderItem = (data) => {
    return (
      <div>
        <div>
          <div className="dx-list-item-icon-container">
              <i className={`dx-icon dx-list-item-icon dx-icon-${data.icon}`}></i>
          </div>
          <span>{data.text}</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <List
          items={navigation}
          width={200} 
          selectionMode="single"
          onSelectionChanged={closeDrawer}
          itemRender={renderItem}
      />
    </div>
  );
}
