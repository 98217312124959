import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { endpoint, dbname, generateHeaders } from './common';

//const queryString = 'SELECT ?uri WHERE { GRAPH ?uri { } }';
//https://dev.sbr.report/dev/

export const namedGraphAPI = createApi({
  reducerPath: 'namedGraphApi',
  baseQuery: fetchBaseQuery({
    baseUrl: endpoint,
    prepareHeaders: generateHeaders
  }),
  tagTypes: ["NamedGraph"],
  endpoints: (build) => ({
    gettNamedGraphs: build.query({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const init = {
          url: `/${dbname}/query?query=${encodeURIComponent('SELECT ?g ?p ?o WHERE { GRAPH ?g { ?g ?p ?o }}')}`,
          //url: `/${dbname}/query?query=${encodeURIComponent('SELECT ?uri WHERE { GRAPH ?uri { } }')}`,
          //url: `/${dbname}/query`,
          //method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/sparql-results+json'
            //'Accept': 'application/Id+json'
            //'Content-Type': 'application/sparql-query'
          },
          //body: 'SELECT ?uri WHERE { GRAPH ?uri { } }'
        }
        const result = await baseQuery(init);
        console.log('Result namedGraphs >> ', result);
        return result.data ? { data: result.data } : { error: result.error };
      },
      providesTags: ["NamedGraph"],
      transformResponse: (response, meta, arg) => {
        //const namedGraphs = response.results.bindings.map(ng => ({uri: ng.uri.value}));
        const namedGraphs = {}
        response.results.bindings.forEach(triple => {
          console.log('Triple >> ', triple);
          if(namedGraphs[triple.g.value] === undefined) {
            namedGraphs[triple.g.value] = {};
            namedGraphs[triple.g.value].iri = triple.g.value;
          }
          const separator = triple.p.value.includes('#') ? '#' : "/"
          const property = triple.p.value.split(separator);
          const propertyName = property[property.length - 1];
          namedGraphs[triple.g.value][propertyName] = triple.o.value;
        });
        console.log('Im getting the named graphs...', response, namedGraphs)
        const allNamedGraphs = Object.values(namedGraphs);
        return allNamedGraphs;
      },
    }),
    getNamedGraphs: build.query({
      query: () => ({
        url: `/${dbname}/query?query=${encodeURIComponent('SELECT ?g ?p ?o WHERE { GRAPH ?g { ?g ?p ?o }}')}`,
        //url: `/${dbname}/query?query=${encodeURIComponent('SELECT ?uri WHERE { GRAPH ?uri { } }')}`,
        //url: `/${dbname}/query`,
        //method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/sparql-results+json'
          //'Accept': 'application/Id+json'
          //'Content-Type': 'application/sparql-query'
        },
        //body: 'SELECT ?uri WHERE { GRAPH ?uri { } }'
      }),
      providesTags: ["NamedGraph"],
      transformResponse: (response, meta, arg) => {
        //const namedGraphs = response.results.bindings.map(ng => ({uri: ng.uri.value}));
        const namedGraphs = {}
        response.results.bindings.forEach(triple => {
          //console.log('Triple >> ', triple);
          if(namedGraphs[triple.g.value] === undefined) {
            namedGraphs[triple.g.value] = {};
            namedGraphs[triple.g.value].iri = triple.g.value;
          }
          const separator = triple.p.value.includes('#') ? '#' : "/"
          const property = triple.p.value.split(separator);
          const propertyName = property[property.length - 1];
          if(namedGraphs[triple.g.value][propertyName]) {
            if(Array.isArray(namedGraphs[triple.g.value][propertyName])) {
              namedGraphs[triple.g.value][propertyName].push(triple.o.value);
            } else {
              namedGraphs[triple.g.value][propertyName] = [
                namedGraphs[triple.g.value][propertyName],
                triple.o.value
              ]
            }
          } else {
            namedGraphs[triple.g.value][propertyName] = triple.o.value;
          }
        });
        console.log('Im getting the named graphs...', response, namedGraphs)
        return Object.values(namedGraphs);
      },
    }),
    doesNamedGraphExist: build.query({
      query: (iri) => ({
        url: `/${dbname}?graph=${encodeURIComponent(iri)}`,
        method: 'HEAD',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      }),
      providesTags: ["NamedGraph"],
      transformResponse: (response, meta, arg) => {
        console.log('Does named graph exist response...', response);
      },
    }),
    putNamedGraph: build.mutation({
      query: ({iri, payload}) => ({
        url: `/${dbname}/?graph=${encodeURIComponent(iri)}`,
        method: 'PUT',
        body: payload,
        headers: {
          'Content-Type': 'text/turtle',
          'Accept': 'application/json'
        }
      }),
      invalidatesTags: ["NamedGraph"],
      transformResponse: (response, meta, arg) => {
        console.log('Im puting the named graph >> ', response);
        return response;
      }
    }),
    deleteNamedGraph: build.mutation({
      query: (iri) => ({
        url: `/${dbname}/?graph=${encodeURIComponent(iri)}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      }),
      invalidatesTags: ["NamedGraph"],
      transformResponse: (response, meta, arg) => {
        console.log('Im deleting the named graph >> ', response);
        return response;
      }
    }),
  })
});

export const {
  useGetNamedGraphsQuery,
  useDoesNamedGraphExistQuery,
  usePutNamedGraphMutation,
  useDeleteNamedGraphMutation,
} = namedGraphAPI;