import shortid from "shortid";
import React, {useState, useCallback} from 'react';
import { useDrag } from 'react-dnd';
import ArrangementContainer from './arrangement-container';
import {ARRANGEMENT} from '../constants';
import store from '../../../store/store'
import { arrangementsSelectors } from '../../../store/arrangement/arrangements'

export default function AggregationPattern(props) {
  const model = arrangementsSelectors.selectById(store.getState(), props.key);
  console.log(model);
  const [whole, setWhole] = useState({name: 'whole', label: 'Whole', id: shortid.generate()});
  const [parts, setParts] = useState([]);
  const [newPart, setNewPart] = useState({name: 'parts', label: 'Insert a new part'});

  let currentNestedPattern;

  const setNestedPattern = (state, props) => ({
    ...state,
    pattern: currentNestedPattern
  });

  const createPart = (state, props) => [
    ...state,
    {
      id: shortid.generate(),
      name: 'part',
      label: 'Part',
      pattern: currentNestedPattern
    }
  ]

  const handleDrop = useCallback(
    (container, arrangement) => {
      //console.log('Aggregation')
      //console.log('container', container)
      //console.log('arrangement', arrangement)
      currentNestedPattern = arrangement.key;
      switch(container.name) {
        case 'whole':
          setWhole(setNestedPattern);
          break;
        case 'parts':
          setParts(createPart);
          break;
        default:
          break;
      }
    }
  )

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: ARRANGEMENT,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }))

  return (
    <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1}}>
      <div ref={drag} className="arrangement">
        <div>Aggregation</div>
        <ArrangementContainer model={whole} onDrop={handleDrop} />
        {
          parts.map(part => (
            <ArrangementContainer key={part.id} model={part} />)
          )
        }
        <ArrangementContainer model={newPart} onDrop={handleDrop} />
      </div>
    </div>
  )
}