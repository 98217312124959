import { withNavigationWatcher } from './contexts/navigation';
import { HomePage, StudioPage, ModelPage, UnitsPage, EntitiesPage, AccountPage, SettingsPage } from './pages';

const routes = [
  {
    path: '/home',
    component: HomePage
  },
  {
    path: '/studio',
    component: StudioPage
  },
  {
    path: '/model',
    component: ModelPage
  },
  {
    path: '/account',
    component: AccountPage
  },
  {
    path: '/settings',
    component: SettingsPage
  }
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
