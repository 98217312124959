import {
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
    
export const namedGraphsAdapter = createEntityAdapter({
  selectId: namedGraph => namedGraph.iri,
  sortComparer: (a, b) => a.iri.localeCompare(b.iri),
});

export const namedGraphsSlice = createSlice({
  name: 'namedGraphs',
  initialState: namedGraphsAdapter.getInitialState(),
  reducers: {
    namedGraphsReceived(state, action) {
      console.log('Inside named graph reveiced >> ', state, action);
      namedGraphsAdapter.setAll(state, action.payload);
    },
    namedGraphUpdated: namedGraphsAdapter.updateOne,
    namedGraphRemoved: namedGraphsAdapter.removeOne
  },
});

export const namedGraphsSelectors = namedGraphsAdapter.getSelectors(state => state.namedGraphs);

export const { 
  namedGraphsReceived
  , namedGraphUpdated
  , namedGraphRemoved
} = namedGraphsSlice.actions;