import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  endpoint
  , dbname
  , generateHeaders
  , getNamedGraphs
  , transformGetNamedGraphs
  , getSchemas
  , transformGetSchemasProp } from './common';

//const queryString = 'SELECT ?uri WHERE { GRAPH ?uri { } }';
//https://dev.sbr.report/dev/

const transformGetResponse = (response, meta, arg) => {
  const reasoningSchemaGraphs = response.data["reasoning.schemas"];
  console.log("reasoning.schemas", reasoningSchemaGraphs);
  const schemas = reasoningSchemaGraphs.map(pair => {
    const keyValuePair = pair.split('=');
    return {
      iri: keyValuePair[1],
      name: keyValuePair[0]
    }
  });
  console.log('Schemas >> ', schemas);
  return schemas;
}

export const getSchemasQuery = async (arg, queryApi, extraOptions, baseQuery) => {
  const currentDB = queryApi.getState().active.baseOntology.name;
  const init = {
    //url: `/admin/databases/${dbname}/options`,
    url: `/admin/databases/${currentDB}/options`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: {
      "reasoning.schema.graphs": null,
      "reasoning.schemas": null
    }
  }
  const result = await baseQuery(init);
  console.log('Result schemas >> ', result);
  return result.data ? { data: transformGetResponse(result) } : { error: result.error };

}

export const schemaAPI = createApi({
  reducerPath: 'schemaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: endpoint,
    prepareHeaders: generateHeaders
  }),
  tagTypes: ["Schema"],
  endpoints: (build) => ({
    getSchemas: build.query({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const currentDB = queryApi.getState().active.baseOntology.name;
        const ngsResult = await getNamedGraphs(baseQuery, currentDB);
        console.log('NamedGraphs result >> ', ngsResult);
        if (ngsResult.data) {
          const namedGraphs = transformGetNamedGraphs(ngsResult.data);
          console.log('NamedGraphs transform >> ', namedGraphs);
          const schemasResult = await getSchemas(baseQuery, currentDB);
          console.log('Result schemas >> ', schemasResult);
          if(!schemasResult.data)
            return { error: schemasResult.error };
          const schemasProp = transformGetSchemasProp(schemasResult);
          const ids = schemasProp.map(schema => schema.iri);
          console.log('ids >> ', ids);
          const schemas = namedGraphs.filter(ng => ids.includes(ng.iri));
          console.log('schemas >> ', schemas);
          return { data: schemas };
        }
        return { error: ngsResult.error };
      },
      providesTags: ["Schema"],
      //transformResponse: transformGetResponse,
    }),
    doesNamedGraphExist: build.query({
      query: ({db, iri}) => ({
        //url: `/${dbname}?graph=${encodeURIComponent(iri)}`,
        url: `/${db}?graph=${encodeURIComponent(iri)}`,
        method: 'HEAD',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      }),
      providesTags: ["Schema"],
      transformResponse: (response, meta, arg) => {
        console.log('Does named graph exist response...', response);
      },
    }),
    putNamedGraph: build.mutation({
      query: ({db, iri, payload}) => ({
        //url: `/${dbname}/?graph=${encodeURIComponent(iri)}`,
        url: `/${db}/?graph=${encodeURIComponent(iri)}`,
        method: 'PUT',
        body: payload,
        headers: {
          'Content-Type': 'text/turtle',
          'Accept': 'application/json'
        }
      }),
      invalidatesTags: ["Schema"],
      transformResponse: (response, meta, arg) => {
        console.log('Im puting the named graph >> ', response);
        return response;
      }
    }),
    deleteNamedGraph: build.mutation({
      query: ({db, iri}) => ({
        //url: `/${dbname}/?graph=${encodeURIComponent(iri)}`,
        url: `/${db}/?graph=${encodeURIComponent(iri)}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      }),
      invalidatesTags: ["Schema"],
      transformResponse: (response, meta, arg) => {
        console.log('Im deleting the named graph >> ', response);
        return response;
      }
    }),
  })
});

export const {
  useGetSchemasQuery,
  useDoesNamedGraphExistQuery,
  usePutNamedGraphMutation,
  useDeleteNamedGraphMutation,
} = schemaAPI;