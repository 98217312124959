import {
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
  
export const arrangementLinksAdapter = createEntityAdapter({
  // Assume IDs are stored in a field other than `book.id`
  selectId: arrangement => arrangement.id,
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => a.id > b.id,
});

export const arrangementLinksSlice = createSlice({
  name: 'arrangement',
  initialState: arrangementLinksAdapter.getInitialState(),
  reducers: {
    // Can pass adapter functions directly as case reducers.  Because we're passing this
    // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
    arrangementLinkAdded: arrangementLinksAdapter.addOne,
    arrangementLinksReceived(state, action) {
      // Or, call them as "mutating" helpers in a case reducer
      arrangementLinksAdapter.setAll(state, action.payload.arrangementLinks)
    },
    arrangementLinkUpdated: arrangementLinksAdapter.updateOne,
    arrangementLinkRemoved: arrangementLinksAdapter.removeOne
  },
});

// Can create a set of memoized selectors based on the location of this entity state
export const arrangementLinksSelectors = arrangementLinksAdapter.getSelectors(state => state.arrangementLinks);