import {
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
    
export const baseOntologiesAdapter = createEntityAdapter({
  selectId: baseOntology => baseOntology.name,
  sortComparer: (a, b) => a.name.localeCompare(b.iri),
});

export const baseOntologiesSlice = createSlice({
  name: 'baseOntologies',
  initialState: baseOntologiesAdapter.getInitialState(),
  reducers: {
    baseOntologiesReceived(state, action) {
      console.log('Inside baseOntologies reveiced >> ', state, action);
      baseOntologiesAdapter.setAll(state, action.payload);
    },
    baseOntologyUpdated: baseOntologiesAdapter.updateOne,
    baseOntologyRemoved: baseOntologiesAdapter.removeOne
  },
});

export const baseOntologiesSelectors = baseOntologiesAdapter.getSelectors(state => state.baseOntologies);

export const { 
  baseOntologiesReceived
  , baseOntologyUpdated
  , baseOntologyRemoved
} = baseOntologiesSlice.actions;