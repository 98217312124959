import {
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
    
export const reportsAdapter = createEntityAdapter({
  selectId: report => report.iri,
  sortComparer: (a, b) => a.iri.localeCompare(b.iri),
});

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: reportsAdapter.getInitialState(),
  reducers: {
    reportsReceived(state, action) {
      console.log('Inside reports reveiced >> ', state, action);
      reportsAdapter.setAll(state, action.payload);
    },
    reportUpdated: reportsAdapter.updateOne,
    reportRemoved: reportsAdapter.removeOne
  },
});

export const reportsSelectors = reportsAdapter.getSelectors(state => state.reports);

export const { 
  reportsReceived
  , reportUpdated
  , reportRemoved
} = reportsSlice.actions;