import { Connection, query, user } from 'stardog';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  endpoint
  , systemConnection
  , dbname
  , roles
  , generateHeaders
  , getNamedGraphs
  , transformGetNamedGraphs
  , getSchemas
  , transformGetSchemasProp } from './common';
//import { namedGraphAPI } from './named-graph';
import murmurHash3 from 'murmurhash3js';

//const queryString = 'SELECT ?uri WHERE { GRAPH ?uri { } }';
//https://dev.sbr.report/dev/


const permissionTemplate = {
  action: null,
  resourceType: "named-graph",
  resources: null
};

const addRoleToUser = async (conn, username, rolename) => {
  const url = conn.request('admin', 'users', username, 'roles');
  const headers = conn.headers();
  headers.set('Content-Type', 'application/json');
  return await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify({ rolename }),
  })
};

const removeRoleFromUser = async (conn, username, rolename) => {
  const url = conn.request('admin', 'users', username, 'roles', rolename);
  const headers = conn.headers();
  return await fetch(url, {
    method: 'DELETE',
    headers
  })
};

export const setUpNamedGraphRoles = async (username, iri) => {
  try {
    const hashcode = murmurHash3.x86.hash32(iri);
    permissionTemplate.resources = [ `${dbname}\\${iri}` ];
    for (let i = 0; i < roles.length; i++) {
      const role = roles[i];
      const name = `r-${role.type}-${hashcode}`;
      console.log('Role name >> ', name);
      await user.role.create(systemConnection, { name });
      if(role.type === 'admin')
        await addRoleToUser(systemConnection, username, name);
      for ( let j = 0; j < role.permissions.length; j++) {
        permissionTemplate.action = role.permissions[j];
        console.log("permissionTemplate ", permissionTemplate);
        await user.role.assignPermission(systemConnection, name, permissionTemplate);
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export const removeNamedGraphRoles = async (iri) => {
  try {
    const hashcode = murmurHash3.x86.hash32(iri);
    permissionTemplate.resources = [ `${dbname}\\${iri}` ];
    for (let i = 0; i < roles.length; i++) {
      const role = roles[i];
      const rolename = `r-${role.type}-${hashcode}`;
      console.log('Role name >> ', rolename);
      const response = await user.role.usersWithRole(systemConnection, rolename );
      console.log('Response >> ', response);
      const users = response.body.users;
      console.log('Users >> ', users);
      for ( let j = 0; j < users.length; j++) {
        const username = users[j];
        console.log("username ", username);
        await removeRoleFromUser(systemConnection, username, rolename);
      }
      await user.role.remove(systemConnection, rolename);
    }
  } catch (e) {
    console.log(e);
  }
}

export const reportAPI = createApi({
  reducerPath: 'reportApi',
  baseQuery: fetchBaseQuery({
    baseUrl: endpoint,
    prepareHeaders: generateHeaders
  }),
  tagTypes: ["Report"],
  endpoints: (build) => ({
    getReports: build.query({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const currentDB = queryApi.getState().active.baseOntology.name;
        const ngsResult = await getNamedGraphs(baseQuery, currentDB);
        console.log('NamedGraphs result >> ', ngsResult);
        if (ngsResult.data) {
          const namedGraphs = transformGetNamedGraphs(ngsResult.data);
          console.log('NamedGraphs transform >> ', namedGraphs);
          const schemasResult = await getSchemas(baseQuery, currentDB);
          console.log('Result schemas >> ', schemasResult);
          if(!schemasResult.data)
            return { error: schemasResult.error };
          const schemasProp = transformGetSchemasProp(schemasResult);
          const ids = schemasProp.map(schema => schema.iri);
          console.log('ids >> ', ids);
          const reports = namedGraphs.filter(ng => !ids.includes(ng.iri));
          //const schemas = namedGraphs.filter(ng => ids.includes(ng.iri));
          //console.log('schemas >> ', schemas);
          //console.log('reports >> ', reports);
          //const result = { data: { reports, schemas } };
          const result = { data: reports };
          console.log('result >> ', result);
          return result;
        }
        return { error: ngsResult.error };
      },
      providesTags: ["Report"]
    }),
    doesReportExist: build.query({
      query: ({db, iri}) => ({
        //url: `/${dbname}?graph=${encodeURIComponent(iri)}`,
        url: `/${db}?graph=${encodeURIComponent(iri)}`,
        method: 'HEAD',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      }),
      transformResponse: (response, meta, arg) => {
        console.log('Does named graph exist response...', response);
      },
    }),
    addReport: build.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery)  => {
        const currentDB = queryApi.getState().active.baseOntology.name;
        console.log('agrs >>> ', arg);
        await setUpNamedGraphRoles(arg.user, arg.iri);
        const ngInit = { 
          //url: `/${dbname}/?graph=${encodeURIComponent(arg.iri)}`,
          url: `/${currentDB}/?graph=${encodeURIComponent(arg.iri)}`,
          method: 'PUT',
          body: arg.payload,
          headers: {
            'Content-Type': 'text/turtle',
            'Accept': 'application/json'
          }
        }
        const ngResult = await baseQuery(ngInit);
        console.log(ngResult);
        return ngResult;
      },
      invalidatesTags: ["Report"],
      transformResponse: (response, meta, arg) => {
        console.log('Im puting the named graph >> ', response);
        return response;
      }
    }),
    deleteReport: build.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery)  => {
        const currentDB = queryApi.getState().active.baseOntology.name;
        const deleteInit = {
          //url: `/${dbname}/?graph=${encodeURIComponent(arg.iri)}`,
          url: `/${currentDB}/?graph=${encodeURIComponent(arg.iri)}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
          }
        }
        const result = await baseQuery(deleteInit);
        if(result)
          await removeNamedGraphRoles(arg.iri);
        return result;
      },
      invalidatesTags: ["Report"],
      transformResponse: (response, meta, arg) => {
        console.log('Im deleting the named graph >> ', response);
        return response;
      }
    }),
  })
});

export const {
  useGetReportsQuery,
  useDoesReportExistQuery,
  useAddReportMutation,
  useDeleteReportMutation,
} = reportAPI;


/*
console.log('addReport arg >> ', arg);
const roleInit = {
  url: `/admin/roles`,
  method: 'POST',
  body: { rolename: null },
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
}
const permissionInit = {
  url: `/admin/permissions/role/`, 
  method: 'PUT',
  body: null,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
}
const hashcode = murmurHash3.x86.hash32(arg.iri);
const roleToUserInit = {
  url: `/admin/users/${arg.user}/roles`, 
  method: 'POST',
  body: { rolename: `r-admin-${hashcode}` },
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
}
roleInit.body.rolename = `r-admin-${hashcode}`;
await baseQuery(roleInit);
//permissionTemplate.resource = [ `${dbname}\\${arg.iri}` ];
//permissionInit.url = permissionInit.url + roleInit.body.rolename;
const roleToUserResponse = await baseQuery(roleToUserInit);
*/
/*
for (let i = 0; i < roles.length; i++) {
  const role = roles[i];
  roleInit.body.rolename = `r-${role.type}-${hashcode}`;
  await baseQuery(roleInit);
  permissionInit.url = permissionInit.url + roleInit.body.rolename;
  for ( let j = 0; j < role.permissions.length; j++) {
    permissionTemplate.action = role.permissions[j];
    permissionInit.body = permissionTemplate;
    await baseQuery(permissionInit);
  }
}
*/
/*
const ngInit = { 
  url: `/${dbname}/?graph=${encodeURIComponent(arg.iri)}`,
  method: 'PUT',
  body: arg.payload,
  headers: {
    'Content-Type': 'text/turtle',
    'Accept': 'application/json'
  }
}
const ngResult = await baseQuery(ngInit);
console.log(ngResult)
console.log('Result roleToUserResponse >> ', roleToUserResponse);
*/