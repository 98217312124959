import React from 'react';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import './model.scss';

export default function Model() {
  return (
    <div className={'content-block'}>
      <TabPanel swipeEnabled={false}>
        {/* icon="textdocument" */}
        <Item title='Aspects'>
          Aspects
        </Item>
        {/* icon="detailslayout" */}
        <Item title='Data types'>
          Data types
        </Item>
      </TabPanel>
    </div>
  )
}