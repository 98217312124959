import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { userAPI } from '../api/user';
import { usersSlice, usersSelectors } from './users';
import userReducer from './slicers/user';
import activeReducer from './slicers/active';
import { permissionAPI } from '../api/permission';
import { permissionsSlice, permissionsSelectors } from './permissions';
import { userRoleAPI } from '../api/user-role';
import { baseOntologyAPI } from '../api/base-ontology';
import { baseOntologiesSlice, baseOntologiesSelectors } from './baseOntologies';
import { namespaceAPI } from '../api/namespace';
import { namespacesSlice, namespacesSelectors } from './namespaces';
import { namedGraphAPI } from '../api/named-graph';
import { namedGraphsSlice, namedGraphsSelectors } from './named-graphs';
import { schemaAPI } from '../api/schema';
import { schemasSlice, schemasSelectors } from './schemas';
import { reportAPI } from '../api/report';
import { reportsSlice, reportsSelectors } from './reports';
import { participantAPI } from '../api/participant';
import { participantsSlice, participantsSelectors } from './participants';
import { arrangementsSlice, arrangementsSelectors } from './arrangement/arrangements';
import { arrangementLinksSlice, arrangementLinksSelectors } from './arrangement/arrangement-links';
//import { api } from '../components/report-grid/queries';

const store = configureStore({
  reducer: {
    [userAPI.reducerPath]: userAPI.reducer,
    users: usersSlice.reducer,
    auth: userReducer,
    active: activeReducer,
    [baseOntologyAPI.reducerPath]: baseOntologyAPI.reducer,
    baseOntologies: baseOntologiesSlice.reducer,
    [namespaceAPI.reducerPath]: namespaceAPI.reducer,
    namespaces: namespacesSlice.reducer,
    [permissionAPI.reducerPath]: permissionAPI.reducer,
    permissions: permissionsSlice.reducer,
    [userRoleAPI.reducerPath]: userRoleAPI.reducer,
    [namedGraphAPI.reducerPath]: namedGraphAPI.reducer,
    namedGraphs: namedGraphsSlice.reducer,
    [schemaAPI.reducerPath]: schemaAPI.reducer,
    schemas: schemasSlice.reducer,
    [reportAPI.reducerPath]: reportAPI.reducer,
    reports: reportsSlice.reducer,
    [participantAPI.reducerPath]: participantAPI.reducer,
    participants: participantsSlice.reducer,
    arrangements: arrangementsSlice.reducer,
    arrangementLinks: arrangementLinksSlice.reducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userAPI.middleware)
      .concat(namespaceAPI.middleware)
      .concat(baseOntologyAPI.middleware)
      .concat(permissionAPI.middleware)
      .concat(userRoleAPI.middleware)
      .concat(namedGraphAPI.middleware)
      .concat(schemaAPI.middleware)
      .concat(reportAPI.middleware)
      .concat(participantAPI.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

store.subscribe(() => {
  console.log(store.getState())
});
  
//console.log(store.getState().arrangements)
  // { ids: [], entities: {} }
const allArrangements = arrangementsSelectors.selectAll(store.getState());
const allNamespaces = namespacesSelectors.selectAll(store.getState());
const allPermissions = permissionsSelectors.selectAll(store.getState());
const allNamedGraphs = namedGraphsSelectors.selectAll(store.getState());
const allReports = reportsSelectors.selectAll(store.getState());
const allUsers = usersSelectors.selectAll(store.getState());
//console.log(allArrangements);
export default store;