export const CLOSE_EDITING = 'CLOSE_EDITING';
export const SET_CHANGES = 'SET_CHANGES';
export const SET_EDIT_ROW_KEY = 'SET_EDIT_ROW_KEY';

export function closeEditing() {
  console.log('Firing >> ', CLOSE_EDITING);
  return {type: CLOSE_EDITING}
}

export function setChanges(changes) {
  console.log('Firing >> ', SET_CHANGES);
  return {
    type: SET_CHANGES,
    payload: changes,
  };
}

export function setEditRowKey(editRowKey) {
  console.log('Firing >> ', SET_EDIT_ROW_KEY);
  return {
    type: SET_EDIT_ROW_KEY,
    payload: editRowKey,
  };
}