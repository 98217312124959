import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest, signOut as sendSignOutRequest } from '../api/auth';

import { useDispatch } from 'react-redux';
import { setCredentials } from '../store/slicers/user';
import { getToken } from '../api/unauth';
import store from '../store/store';

function AuthProvider(props) {

  const dispatch = useDispatch();

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }
      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (email, password) => {
    const tokenResult = await getToken(email, password);
    console.log("Gotten token >> ", tokenResult);
    const result = await sendSignInRequest(email, password);
    console.log("Gotten user >> ", result);
    if (tokenResult.isOk && result.isOk) {
      const credentials = {
        user: result.data.attributes,
        token: tokenResult.token
      }
      console.log('Credentials >> ', credentials);
      //dispatch(setCredentials({payload: credentials}));
      dispatch(setCredentials(credentials));
      setUser(result.data);
    }
    return result;
  }, []);

  const signOut = useCallback(async () => {
    const result = await sendSignOutRequest();
    if(result.isOk) {
      setUser();
    } else {
      alert(result.message);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
  );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
