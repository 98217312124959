import React, {useState, useEffect, useCallback} from 'react';
import { Button } from 'devextreme-react/button';
import ConfirmDialog from "../confirm-dialog";

import '../common.scss';


export default function ArrangementHeader(props) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const ativateDeleteDialog = () => {
    console.log("activateDeleteDialog");
    setShowDeleteDialog(true);
  }
  const hideDeleteDialog = () => {
    console.log("hideDeleteDialog");
    setShowDeleteDialog(false);
  }
  const ativateUpdateDialog = () => {
    console.log("activateUpdateDialog");
    setShowUpdateDialog(true);
  }
  const hideUpdateDialog = () => {
    console.log("hideUpdateDialog");
    setShowUpdateDialog(false);
  }
  const update = () => {
    props.onUpdate();
    hideUpdateDialog();
  }
  const destroy = () => {
    props.onDestroy();
    hideDeleteDialog();
  }
  return (
    <div className="arrangement-header">
      <div><Button icon="edit" onClick={ativateUpdateDialog}/></div>
      <div><Button icon="trash" onClick={ativateDeleteDialog}/></div>
      <div>{props.parent.pattern.title}</div>
      <ConfirmDialog title="Arrangement properties" visible={showUpdateDialog} onCancel={hideUpdateDialog} onConfirm={update} />
      <ConfirmDialog title="Delete arrangement" visible={showDeleteDialog} onCancel={hideDeleteDialog} onConfirm={destroy} />
    </div>
  )
}