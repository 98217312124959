import {
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
    
export const usersAdapter = createEntityAdapter({
  selectId: user => user.email,
  sortComparer: (a, b) => a.email.localeCompare(b.email),
});

export const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState(),
  reducers: {
    usersReceived(state, action) {
      console.log('Inside users reveiced >> ', state, action);
      usersAdapter.setAll(state, action.payload);
    },
    userUpdated: usersAdapter.updateOne,
    userRemoved: usersAdapter.removeOne
  },
});

export const usersSelectors = usersAdapter.getSelectors(state => state.users);

export const { 
  usersReceived
  , userUpdated
  , userRemoved
} = usersSlice.actions;