import {
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
    
export const namespacesAdapter = createEntityAdapter({
  selectId: namespace => namespace.name,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});
export const namespacesSlice = createSlice({
  name: 'namespaces',
  initialState: namespacesAdapter.getInitialState(),
  reducers: {
    namespaceAdded: namespacesAdapter.addOne,
    namespacesAdded: namespacesAdapter.addMany,
    namespacesReceived(state, action) {
      //console.log('Inside reveiced >> ', state, action);
      namespacesAdapter.setAll(state, action.payload)
    },
    namespaceUpdated: namespacesAdapter.updateOne,
    namespaceRemoved: namespacesAdapter.removeOne
  },
});

export const { namespacesReceived, namespaceAdded, namespacesAdded } = namespacesSlice.actions;

export const namespacesSelectors = namespacesAdapter.getSelectors(state => state.namespaces);