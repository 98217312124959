import store from '../../../store/store';
import { arrangementsSlice, arrangementsSelectors } from '../../../store/arrangement/arrangements';
import { arrangementLinksSlice, arrangementLinksSelectors } from '../../../store/arrangement/arrangement-links';

const {arrangementRemoved} = arrangementsSlice.actions;
const {arrangementLinkRemoved} = arrangementLinksSlice.actions;

export const deleteDependencies = (item) => {
  const allArrangementLinks = arrangementLinksSelectors.selectAll(store.getState());
  allArrangementLinks.forEach(link => {
    if(link.from === item.id) {
      const descendant = arrangementsSelectors.selectById(store.getState(), link.to);
      deleteDependencies(descendant);
      store.dispatch(arrangementRemoved(descendant.id));
      store.dispatch(arrangementLinkRemoved(link.id));
    }
  });
}