import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { endpoint, generateHeaders } from './common';

export const userRoleAPI = createApi({
  reducerPath: 'userRoleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: endpoint,
    prepareHeaders: generateHeaders
  }),
  tagTypes: ["UserRole"],
  endpoints: (build) => ({
    getRoles: build.query({
      query: () => ({
        url: `/admin/roles`,
        headers: {
          'Accept': 'application/json'
        }
      }),
      providesTags: ["UserRole"],
      transformResponse: (response, meta, arg) => {
        console.log('Im getting the roles...', response);
        return response.roles;
      },
    }),
    getUsersWithRole: build.query({
      query: (role) => ({
        url: `/admin/roles/${role}/users`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }),
      providesTags: ["Participant"],
      transformResponse: (response, meta, arg) => {
        console.log('Im getting the users with role...', response);
        return response.users;
      },
    }),
    addRole: build.mutation({
      query: (rolename) => ({
        url: `/admin/roles`,
        method: 'POST',
        body: {rolename},
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }),
      invalidatesTags: ["UserRole"],
      transformResponse: (response, meta, arg) => {
        console.log('Im adding role...', response);
        return response;
      }
    }),
    deleteRole: build.mutation({
      query: (role) => ({
        url: `/admin/roles/${role}`,
        method: 'DELETE',
        headers: {
          'Accept': 'application/json'
        }
      }),
      invalidatesTags: ["UserRole"],
      transformResponse: (response, meta, arg) => {
        console.log('Im deleting role... ', response);
        return response;
      }
    }),
  })
});

export const {
  useGetRolesQuery
  , useGetUsersWithRoleQuery
  , useAddRoleMutation
  , useDeleteRoleMutation
} = userRoleAPI;