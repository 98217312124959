import {
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

const idGenerator = (permission) => `${permission.action}:${permission.resource_type}:${permission.resource.join('.')}`;
    
export const permissionsAdapter = createEntityAdapter({
  selectId: permission => idGenerator(permission),
  sortComparer: (a, b) => idGenerator(a).localeCompare(idGenerator(b)),
});
export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: permissionsAdapter.getInitialState(),
  reducers: {
    permissionsReceived(state, action) {
      //console.log('Inside reveiced >> ', state, action);
      permissionsAdapter.setAll(state, action.payload)
    },
    permissionAdded: permissionsAdapter.addOne,
    permissionUpdated: permissionsAdapter.updateOne,
    permissionRemoved: permissionsAdapter.removeOne
  },
});

export const permissionsSelectors = permissionsAdapter.getSelectors(state => state.permissions);

export const { 
  permissionsReceived
  , permissionAdded
  , permissionUpdated
  , permissionRemoved
} = permissionsSlice.actions;

