import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'active',
  initialState: { 
    baseOntology: null
    , model: null
    , report: null
    , structure: null
  },
  reducers: {
    activateBaseOntology: (state, action) => {
      console.log('Action >> ', action);
      const { payload } = action;
      console.log('Inside activateBaseOntology >> ', payload);
      state.baseOntology = payload;
    },
    activateModel: (state, action) => {
      console.log('Action >> ', action);
      const { payload } = action;
      console.log('Inside activateReport >> ', payload);
      state.model = payload;
    },
    activateReport: (state, action) => {
      console.log('Action >> ', action);
      const { payload } = action;
      console.log('Inside activateReport >> ', payload);
      state.report = payload;
    }
  },
})

export const { activateBaseOntology, activateModel, activateReport } = slice.actions;

export default slice.reducer;

export const selectActiveBaseOntology = (state) => state.active.baseOntology;
export const selectActiveModel = (state) => state.active.model;
export const selectActiveReport = (state) => state.active.report;