import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { endpoint, dbname, generateHeaders } from './common';

const dbNamespaces = [
  "http://purl.org/dc/terms/",
  "http://purl.org/vocab/vann/",
  "http://www.w3.org/2000/01/rdf-schema#",
  "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
  "http://api.stardog.com/",
  "http://www.w3.org/2002/07/owl#",
  "tag:stardog:api:"
]

const cleanSystemNS = namespace => !dbNamespaces.some(dbname => dbname === namespace.name);

//https://redux-toolkit.js.org/rtk-query/usage/examples#dispatching-an-action-to-set-the-user-state
export const namespaceAPI = createApi({
  reducerPath: 'namespaceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: endpoint,
    prepareHeaders: generateHeaders
  }),
  tagTypes: ["Namespace"],
  endpoints: (build) => ({
    getNamespaces: build.query({
      query: (db) => ({
        //url: `/${dbname}/namespaces`,
        url: `/${db}/namespaces`,
        headers: {
          'Accept': 'application/json'
        }
      }),
      providesTags: ["Namespace"],
      transformResponse: (response, meta, arg) => {
        console.log('Im getting the namespaces...');
        return response.namespaces.filter(cleanSystemNS);
      },
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }
      ) {},
      // The 2nd parameter is the destructured `QueryCacheLifecycleApi`
      async onCacheEntryAdded(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          cacheEntryRemoved,
          cacheDataLoaded,
          getCacheEntry,
          updateCachedData,
        }
      ) {},
    }),
    addNamespaces: build.mutation({
      query: ({db, body}) => ({
        //url: `/${dbname}/namespaces`,
        url: `/${db}/namespaces`,
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'text/turtle',
          'Accept': 'application/json'
        }
      }),
      invalidatesTags: ["Namespace"],
      transformResponse: (response, meta, arg) => {
        const namespaces = response.namespaces;
        const jsonNS = namespaces.map((namespace) => {
          const parts = namespace.split("=");
          return {
            prefix: parts[0],
            name: parts[1]
          }
        })
        console.log('Im adding the namespaces...');
        return jsonNS.filter(cleanSystemNS);
      }
    }),
  })
});

export const { useGetNamespacesQuery, useAddNamespacesMutation } = namespaceAPI;