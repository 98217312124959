import React from 'react';
import TagBox from 'devextreme-react/tag-box';

export default function SchemaMultiselector(props) {

  const onValueChanged = (e) => {
    props.data.setValue(e.value);
  }

  const onSelectionChanged = () => {
    props.data.component.updateDimensions();
  }
  
  return (<TagBox
    dataSource={props.data.column.lookup.dataSource}
    defaultValue={props.data.value}
    valueExpr="iri"
    displayExpr="title"
    showSelectionControls={true}
    maxDisplayedTags={1}
    showMultiTagOnly={false}
    applyValueMode="useButtons"
    searchEnabled={true}
    onValueChanged={onValueChanged}
    onSelectionChanged={onSelectionChanged} />);
}
