import React from 'react';
import ParticipantGrid from '../../components/participant-grid/participant-grid';
import ReportDesigner from '../../components/report-designer/report-designer';
import DataSourcesHandler from '../../components/data-sources-handler/data-sources-handler';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import './studio.scss';

export default function Studio() {
  return (
    <div className={'content-block'}>
      <TabPanel swipeEnabled={false}>
        {/* icon="textdocument" */}
        <Item title='Report'>
          <ReportDesigner title={'Components'}/>
        </Item>
        {/* icon="detailslayout" */}
        <Item title='Base knowledge'>
          This should be the base knowledge
        </Item>
        <Item title='Participants'>
          <ParticipantGrid title={'Permissions'} />
        </Item>
        <Item title='Data sources'>
          <DataSourcesHandler/>
        </Item>
        <Item title='Export'>
          This should be the export options
        </Item>
      </TabPanel>
    </div>
  )
}