import shortid from "shortid";
import React, {useState, useCallback} from 'react';
import ArrangementContainer from './arrangement-container'
import store from '../../../store/store';
import { arrangementsSlice } from '../../../store/arrangement/arrangements';
import { arrangementLinksSlice } from '../../../store/arrangement/arrangement-links';
import { deleteDependencies } from './utils';

export default function ProsePattern(props) {
  
  //console.log('Prose id', props.id);
  const {arrangementAdded, arrangementUpdated, arrangementRemoved} = arrangementsSlice.actions;
  const {arrangementLinkAdded, arrangementLinkUpdated, arrangementLinkRemoved} = arrangementLinksSlice.actions;
  
  //console.log(store.getState());
  //{name: 'children', label: 'Children'}
  const [children, setChildren] = useState([]);
  const [newChild, setNewPart] = useState({name: 'children', label: 'Insert a new child'});

  let newChildId;
  const createChild = (state, properties) => {
    const child = {
      id: shortid.generate(),
      role: 'child',
      label: 'Child',
      from: props.id,
      to: newChildId
    }
    store.dispatch(arrangementLinkAdded(child));
    return [
    ...state,
    child
  ]}

  const insertChild = useCallback(
    (container, pattern) => {
      //console.log('Prose')
      //console.log('container', container)
      //console.log('arrangement', arrangement)
      //currentNestedPattern = arrangement.key;
      newChildId = shortid.generate();
      store.dispatch(arrangementAdded({ id: newChildId, pattern: pattern}));
      
      if(container.name === 'children') {
        setChildren(createChild);
        //const children = [...arrangement.children, child];
        //store.dispatch(arrangementUpdated({ id: props.id, changes: {children}}));
      }
      console.log(store.getState());
    }
  )

  /*
  let updatedChildId;
  const editChild = (state, properties) => {
    const child = {
      id: shortid.generate(),
      role: 'child',
      label: 'Child',
      from: props.id,
      to: newChildId
    }
    store.dispatch(arrangementLinkAdded(child));
    return [
    ...state,
    child
  ]}
  */

  const updateChild = useCallback(
    (child) => {
      //console.log('Prose')
      //console.log('container', container)
      //console.log('arrangement', arrangement)
      //currentNestedPattern = arrangement.key;
      store.dispatch(arrangementUpdated(child));
      console.log(store.getState());
    }
  )

  let deletedChildId;
  const deleteChild = (state, properties) => {
    console.log("childrenBeforeDelete", state);
    const childrenAfterDelete = state.filter(item => {
      const result = item.to !== deletedChildId;
      if(!result)
        store.dispatch(arrangementLinkRemoved(item.id));
      return result;
    })
    console.log("childrenAfterDelete", childrenAfterDelete);
    return childrenAfterDelete;
  }

  const removeChild = useCallback(
    (child) => {
      //console.log('Item to destroy', child);
      console.log('State before destroy', store.getState());
      deleteDependencies(child);
      store.dispatch(arrangementRemoved(child.id));
      deletedChildId = child.id
      setChildren(deleteChild);
      console.log('State after destroy', store.getState());
    }
  )

  return (
    <React.Fragment>
      {
        children.map(child => (
          <ArrangementContainer
            key={child.to}
            id={child.to}
            model={child}
            onDestroy={removeChild}
            onUpdate={updateChild}/>)
        )
      }
      <ArrangementContainer model={newChild} onDrop={insertChild} />
    </React.Fragment>
  )
}