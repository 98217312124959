//https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
import React, { useState, useReducer, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useGetNamespacesQuery } from '../../api/namespace';
import { useGetSchemasQuery } from '../../api/schema';
import { 
  useGetNamedGraphsQuery
  , usePutNamedGraphMutation
  , useDeleteNamedGraphMutation
} from '../../api/named-graph';
import { namedGraphsReceived } from '../../store/named-graphs';
import { namedGraphsSelectors } from '../../store/named-graphs';
import { namespacesReceived } from '../../store/namespaces';
import { namespacesSelectors } from '../../store/namespaces';
import { schemasReceived } from '../../store/schemas';
import { schemasSelectors } from '../../store/schemas';
import { activateModel } from '../../store/slicers/active';
import store from '../../store/store';

import reducer from './reducer.js';
import { closeEditing, setChanges, setEditRowKey } from './actions.js';

import DataGrid, {
  Column,
  Lookup,
  Editing,
  Paging,
  Selection,
  Button
} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import SchemaMultiselector from './schema-multiselector';
//import NamespaceSelector from './namespace-selector';

//import applyChanges from 'devextreme/data/apply_changes';

//READ::named-graph::dev-db\https://dev.sbr.report/dev/
//READ::named-graph::dev-db
//READ::db::dev-db

const loadPanelPosition = { of: '#report-grid' };

const m = `
@prefix vann: <http://purl.org/vocab/vann/> .
@prefix dct: <http://purl.org/dc/terms/> .
@prefix xsd: <http://www.w3.org/2001/XMLSchema#> .

devsbr:report1 dct:title "report 1"@en ;
    dct:creator "danielmontoya.contacto@gmail.com" ;
    dct:date "2022-05-08T00:00:00Z"^^xsd:dateTime ;
    vann:preferredNamespaceUri "https://dev.sbr.report/" .
`
const im = `
owl:imports
  <https://www.sbr.report/sbrm/2022/06/constructs#> ,
  <https://www.sbr.report/sbrm/2022/06/measures#> .
`
const importsToTurtle = (imports) => {
  if(imports.length == 0)
    return ''
  const turtle =
  ` owl:imports
      ${imports.map(iri => `<${iri}>`).join(' , ')} .`
  return turtle;
}
const ngJsonToTurtle = (report) => {
  const lang = window.navigator.language ? window.navigator.language : 'en';
  const iri = report.preferredNamespaceUri + report.title.replace(/\s/g, '') + '/';
  const email = store.getState().auth.user.email;
  const today = new Date();
  today.setMilliseconds(0)
  const date = today.toISOString();

  const payload = 
  `
  @prefix vann: <http://purl.org/vocab/vann/> .
  @prefix dct: <http://purl.org/dc/terms/> .
  @prefix xsd: <http://www.w3.org/2001/XMLSchema#> .
  ${report.imports ? '@prefix owl: <http://www.w3.org/2002/07/owl#> .' : ''}

  <${iri}> dct:title "${report.title}"@${lang} ;
    dct:creator "${email}" ;
    dct:date "${date}"^^xsd:dateTime ;
    vann:preferredNamespaceUri "${report.preferredNamespaceUri}" ${report.imports ? ' ;' : ' .'}
    ${importsToTurtle(report.imports)}
  `
  console.log('Turtle >> ', payload);
  return { iri, payload};
}

const initialState = {
  changes: [],
  editRowKey: null,
};

export default function ReportGrid() {

  const db = store.getState().active.baseOntology.name;
  
  const dispatcher = useDispatch();

  const {
    data: nsData = []
    , isLoading: nsIsLoading
    , isFetching: nsIsFetching
    , isError: nsIsError
    , isSuccess: nsIsSuccess } = useGetNamespacesQuery(db);

  const {
    data: sData = []
    , isLoading: sIsLoading
    , isFetching: sIsFetching
    , isError: sIsError
    , isSuccess: sIsSuccess } = useGetSchemasQuery();

  const { data = []
    , isLoading
    , isFetching
    , isError
    , isSuccess
    , error } = useGetNamedGraphsQuery();
  
  const [ putNamedGraph, {
    data: addedData, 
    isLoading: isAdding,
    isError: isAddError,
    isSuccess: isAddSuccess
  }] = usePutNamedGraphMutation();
  
  const [ deleteNamedGraph, {
    data: deletedData, 
    isLoading: isDeleting,
    isError: isDeleteError,
    isSuccess: isDeleteSuccess
  }] = useDeleteNamedGraphMutation();  

  const ids = sData.map(s => s.iri);
  console.log('ids >> ', ids);
  const reports = data.filter(ng => !ids.includes(ng.iri));
  console.log('reports >> ', reports);
  const models = data.filter(ng => ids.includes(ng.iri));
  
  dispatcher(namespacesReceived(nsData));
  dispatcher(schemasReceived(models));
  dispatcher(namedGraphsReceived(reports));
  //console.log('State after >> ', store.getState().namespaces);
  const namespaces = namespacesSelectors.selectAll(store.getState());
  const schemas = schemasSelectors.selectAll(store.getState());
  const namedGraphs = namedGraphsSelectors.selectAll(store.getState());

  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);

  const selectionChanged = (data) => {
    setSelectedItemKeys(data.selectedRowKeys);
  }

  const handlePut = async (ngData) => {
    const options = ngJsonToTurtle(ngData);
    console.log('Options', options)
    const newNamedGraph = await putNamedGraph(options).unwrap();
    console.log('closeEditing ', closeEditing());
    dispatch(closeEditing());
    return newNamedGraph ? newNamedGraph : null;
  }

  const handleDelete = async (iri) => {
    const deletedNamedGraph = await deleteNamedGraph(iri).unwrap();
    console.log('deletedNamedGraph >> ', deletedNamedGraph);
    console.log('closeEditing ', closeEditing());
    dispatch(closeEditing());
    return deletedNamedGraph ? deletedNamedGraph : null;
  }

  const onSaving = useCallback((e) => {
    e.cancel = true;
    //console.log('Event >> ', e);
    //console.log('changes >> ', e.changes);
    const change = e.changes[0];
    if (change && change.type) {
      if(change.type === 'insert') {
        //console.log('namedGraphs >> ', namedGraphs);
        //console.log('change >> ', change);
        //const newData = applyChanges(namedGraphs, [change], { keyExpr: 'iri' });
        //console.log('newData >> ', newData);
        console.log('Insert change.data >> ', change.data);
        e.promise = handlePut(change.data);
      } else if (change.type === 'remove') {
        console.log('Delete change.data >> ', change.key);
        e.promise = handleDelete(change.key);
      } //else if (change.type === 'update') {}
    } else {
      console.log('closeEditing ', closeEditing());
      dispatch(closeEditing());
    }
  }, []);

  const onChangesChange = useCallback((changes) => {
    console.log('changes', changes);
    //setChanges(dispatch, changes);
    dispatch(setChanges(changes));
  }, []);

  const onEditRowKeyChange = useCallback((editRowKey) => {
    console.log('editRowKey', editRowKey);
    //setEditRowKey(dispatch, editRowKey);
    dispatch(setEditRowKey(editRowKey));
  }, []);

  const itemRender = (data) => {
    if (data != null) {
      return <div>
        <div><b>{data.prefix}</b></div>
        <div>{data.name}</div>
      </div>;
    }
    return <span>(All)</span>;
  }

  function statusEditorRender(cell) {
    const onValueChanged = (e) => {
      //console.log('onValueChanged >> ', cell, e);
      cell.setValue(e.value);
    }
    return <SelectBox
      searchEnabled
      defaultValue={cell.value}
      {...cell.column.lookup}
      onValueChanged={onValueChanged}
      itemRender={itemRender}
    />;
  }

  const isOpenIconVisible = (e) => {
    return !e.row.isEditing;
  }
  
  const activateNamedGraph = (e) => {
    dispatcher(activateModel(e.row.data));
  }

  const cellTemplate = (container, options) => {
    console.log("cellTemplate options >> ", options)
    const noBreakSpace = '\u00A0';
    let value;
    if (Array.isArray(options.value)) {
      value = options.value
    } else if(options.value) {
      value = [options.value]
    } else {
      value = []
    }
    const text = value.map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
    container.textContent = text || noBreakSpace;
    container.title = text;
  }

  const calculateFilterExpression = (filterValue, selectedFilterOperation, target) => {
    if (target === 'search' && typeof (filterValue) === 'string') {
      return [this.dataField, 'contains', filterValue];
    }
    return function(data) {
      return (data["imports"] || []).indexOf(filterValue) !== -1;
    };
  }

  //render() {
  return (
    <div id="schema-grid-wrapper">
      <LoadPanel
        position={loadPanelPosition}
        visible={(isLoading || nsIsLoading || sIsLoading) || (isAdding || isDeleting )}
      />
      {(isFetching || nsIsFetching || sIsError) && <div>Fetching...</div>}
      {(isError || nsIsError || sIsError) && <div>Something went wrong</div>}
      {(isSuccess && nsIsSuccess && sIsSuccess) && (
        <DataGrid id="schema-grid"
          keyExpr="iri"
          dataSource={schemas}
          showBorders
          repaintChangesOnly
          selectedRowKeys={selectedItemKeys}
          onSelectionChanged={selectionChanged}
          onSaving={onSaving}
        >
          <Selection mode="multiple" />
          <Paging enabled={false} />
          <Editing
            mode="row"
            useIcons
            allowAdding
            allowDeleting
            changes={state.changes}
            onChangesChange={onChangesChange}
            editRowKey={state.editRowKey}
            onEditRowKeyChange={onEditRowKeyChange}
            />
          <Column
            dataField="iri"
            caption="Prefix"
            width={200}
            editCellRender={statusEditorRender}
          >
            <Lookup
              dataSource={namespaces}
              valueExpr="name"
              displayExpr="prefix"
            />  
          </Column>
          <Column
            dataField="title"
            caption="Name"
            />
          <Column
            dataField="imports"
            caption="Use model"
            width={250}
            allowSorting={false}
            editCellComponent={SchemaMultiselector}
            cellTemplate={cellTemplate}
            calculateFilterExpression={calculateFilterExpression}>
            <Lookup
              dataSource={schemas}
              valueExpr="iri"
              displayExpr="title"
            />
          </Column>
          <Column
            dataField="date"
            dataType="datetime"
            allowEditing={false}
            caption="Creation date"
            />
          <Column
            dataField="creator"
            allowEditing={false}
            caption="Created by"
            />
          <Column type="buttons" width={200}>
            <Button name="edit" />
            <Button name="delete" />
            <Button
              icon="folder"
              hint="Open"
              visible={isOpenIconVisible}
              onClick={activateNamedGraph}
            />
          </Column>
        </DataGrid>
        )}
    </div>
  );
}