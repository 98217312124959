//import { Connection, query, user } from 'stardog';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { endpoint, generateHeaders } from './common';

export const userAPI = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: endpoint,
    prepareHeaders: generateHeaders
  }),
  endpoints: (build) => ({
    getUsers: build.query({
      query: () => ({
        url: `/admin/users`,
        headers: {
          'Accept': 'application/json'
        }
      }),
      providesTags: ["User"],
      transformResponse: (response, meta, arg) => {
        console.log('Im getting the users...', response);
        const appUsers = response.users.filter(user => user.includes('@'));
        return appUsers.map(user => ({ email: user }));
      }
    }),
    getRolesOfUser: build.query({
      query: (user) => ({
        url: `/admin/users/${user}/roles`,
        headers: {
          'Accept': 'application/json'
        }
      }),
      providesTags: ["CurrentUserRole"],
      transformResponse: (response, meta, arg) => {
        console.log('Im getting the users...', response);
        return response.roles;
      }
    }),
    getToken: build.query({
      query: (credentials) => ({
        url: 'admin/token',
        headers: {
          'Authorization': `Basic ${Buffer.from(`${credentials.username}:${credentials.password}`).toString('base64')}`
        },
      }),
      transformResponse: (response, meta, arg) => response.body.token
    })
  })
})

export const { 
  useGetUsersQuery,
  useGetTokenQuery
} = userAPI;