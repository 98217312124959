import React from 'react';
import BaseOntology from './base-ontology';
import BaseOntologySelector from './base-ontology-selector';
import './home.scss';

export default function Home() {
  return (
    <div className={'content-block'}>
      <BaseOntologySelector/>
      <BaseOntology/>
    </div>
  )
}
