import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null },
  reducers: {
    setCredentials: (
      state,
      action
    ) => {
      console.log('Action >> ', action);
      const { payload: { user, token } } = action;
      console.log('Inside credentials >> ', state, user, token);
      state.user = user
      state.token = token
    },
  },
})

export const { setCredentials } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state) => state.auth.user