import React, { useState, useCallback, useRef } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import LayoutContainer from './layout-container';
import { DndProvider } from 'react-dnd';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import shortid from "shortid";
import DisclosureContainer from './disclosure-container';
//import Button from 'devextreme-react/button';
//import Drawer from 'devextreme-react/drawer';

const shortId = shortid.generate();
export default function ReportEditor(props) {  
  const [rootId, setRootId] = useState(shortId);
  return (
    <TabPanel swipeEnabled={false}>
      <Item title="Publishing">
        This should be the publishing preview
      </Item>
      <Item title="Rendering">
        <DisclosureContainer id={rootId} />
      </Item>
      <Item title="Model structure">
        <DndProvider backend={HTML5Backend}>
          <LayoutContainer id={rootId}/>
        </DndProvider>
      </Item>
      <Item title="Fact table">
        This should be the fact table
      </Item>
      <Item title="Concepts">
        This should be the concepts table
      </Item>
      <Item title="Business rules structure">
        This should be the business rules structure
      </Item>
      <Item title="Business rules validation results">
        This should be the business rules validation results
      </Item>
    </TabPanel>
  );
}